import { React, CenterWrapper, View, Button, Image, Text } from 'lib'
import { Theme, HTMLStylesDark } from 'app'
import { CSSInterpolation } from 'types/style'

import page404 from 'assets/404_image.png'
import trackingEnd from 'assets/tracking-end.png'

type RouteFallbackProps = {
  title: string;
  description: string;
  image: string;
  imageStyles?: CSSInterpolation | any;
}

const statusObj = {
  inactive: {
    title: 'Tracking Ended',
    description: 'The user has ended their journey or confirmed their safety, so live sharing of their location has now stopped.\n\n Thanks for being a Becon Key Contact!',
    image: trackingEnd,
    imageStyles: { maxWidth: 280, maxHeight: 350 },
  },
  disabled: {
    title: 'Tracking Ended',
    description: 'The user has ended their journey or confirmed their safety, so live sharing of their location has now stopped.\n\n Thanks for being a Becon Key Contact!',
    image: trackingEnd,
    imageStyles: { maxWidth: 280, maxHeight: 350 },
  },
  expired: {
    title: 'Invalid Link',
    description: 'This link is broken. Please email contact@becontheapp.com',
    image: page404,
    imageStyles: { maxWidth: 350, maxHeight: 350 },
  },
  'not found': {
    title: 'Invalid Link',
    description: 'This link is broken. Please email contact@becontheapp.com',
    image: page404,
    imageStyles: { maxWidth: 350, maxHeight: 350 },
  },
}

const RouteFallback: React.FC<RouteFallbackProps> = ({ status }) => {
  if (!Object.keys(statusObj).find(obj => obj === status)) return null

  const { title, description, image, imageStyles } = statusObj?.[status]

  return (
    <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.innerWrapper}>
      <View style={styles.imageWrapper}>
        <Image
          source={image}
          alt={`${title} image`}
          style={[styles.image, imageStyles || {}]}
        />
      </View>
      <Text variant={`h1 greyscale1 marginTop:1 textCenter`} text={title}/>
      <Text variant={`p1 textCenter`} style={styles.text} text={description}/>
      <Button
        to='/'
        text='Back'
        style={{ width: 'fit-content' }}
        variant={'gradient noDecoration'}
        textStyle={styles.buttonText}
      />
    </CenterWrapper>
  )
}

export default RouteFallback

const styles = {
  wrapper: {
    ...Theme.flex,
    marginTop: Theme.spacing(20),
    marginBottom: Theme.spacing(5),
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(12),
      marginBottom: Theme.spacing(5),
    },
  },
  innerWrapper: {
    ...HTMLStylesDark.plainText,
    gap: Theme.spacing(3),
    flexDirection: 'column',
    alignItems: 'center',
  },
  imageWrapper: {
    maxWidth: 750,
  },
  text: {
    whiteSpace: 'pre-line',
  },
  image: {
    objectFit: 'contain',
    width: '100%',
    height: 'auto',
  },
  buttonText: {
    color: Theme.colors.white,
  },
}
